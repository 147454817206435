
import { defineComponent, ref } from 'vue';
import { HeartOutlined } from '@ant-design/icons-vue';

const desc: string[] = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

export default defineComponent({
  name: 'RateExample',
  setup() {
    const value = ref(2);
    const value1 = ref(2);
    const value2 = ref(2.5);
    const value3 = ref(0.5);

    return {
      value,
      value1,
      value2,
      value3,

      desc,
    };
  },
  components: {
    HeartOutlined,
  },
});
